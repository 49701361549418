.clinicasContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.clinicaAddBtnDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3vh 0px;
}
.clinicaAddBtn {
    background-color: var(--escuro) !important;
    color: #fff !important;
    font-weight: bold !important;
}
.clinicasCards {
    justify-content: center;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 5%;
}
.clinicasCards > div {
    margin-bottom: 2%;
}
.clinicaLoader {
    height: 30px;
    width: 30px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    left: 0;
    right: 0;
    color: var(--claro) !important;
}
.clinicasArrayVazio {
    margin-top: 10vh;
    text-align: center;
    font-family: "LemonMilk";
    font-size: 120%;
    font-weight: 700;
}
.loadingClinicas {
    color: var(--escuro) !important;
    width: 28px !important;
    height: 28px !important;
}

@media screen and (max-width: 530px) {
    .clinicasCards {
        width: 90%;
    }
}
