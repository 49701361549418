.perfilContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.perfilTitulo {
    width: 100%;
    margin: 3vh 0;
    text-align: center;
    font-family: "LemonMilk";
    font-weight: 600;
    font-size: 230%;
    color: var(--escuro);
}
.perfilDivider {
    width: 70vw;
    height: 5px;
    border-radius: 55px;
    margin: auto;
    background-color: var(--escuro);
}
.perfilInfo {
    display: flex;
    width: 70vw;
}
.perfilInfo > div:first-child {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.perfilUserIcon {
    font-size: 1000% !important;
    margin-top: 8px;
    align-self: center;
    color: var(--escuro);
}
.perfilDados,
.perfilDadosD {
    display: flex;
    flex-direction: column;
    padding-right: 10%;
    padding-left: 10%;
    margin-top: 3vh;
}
.perfilDadosD {
    padding-right: 35%;
}
.perfilDados > div,
.perfilDadosD > div {
    margin-bottom: 13px;
}
.perfilInfo > div:nth-child(2) {
    width: 50%;
}
.perfilInfo > div:nth-child(2) > p:first-child {
    margin: 5vh 0 1vh 5%;
    font-family: "LemonMilk";
    font-weight: 600;
    font-size: 130%;
    color: var(--escuro);
}
.perfilInfoDivider {
    width: 50%;
    height: 4px;
    margin-left: 4%;
    border-radius: 15px;
    background-color: var(--escuro);
}
.perfilNiver {
    width: 170px;
}
.perfilUF {
    width: 80px;
}
.perfilEditarbtn {
    margin-top: 2%;
    align-self: center;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: var(--escuro);
    color: white;
    font-size: 1.2rem;
    font-family: "Catamaran";
    font-weight: 700;
    margin-bottom: 10vh;
    cursor: pointer;
}
.perfilEditarBtns {
    display: none;
    cursor: pointer;
}
.perfilInput .MuiOutlinedInput-input {
    padding: 10px !important ;
}
.perfilInput .MuiAutocomplete-inputRoot {
    padding: 0px !important ;
}
.perfilInput .MuiInputBase-input.Mui-disabled {
    color: black;
}
.perfilInput .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1);
}
.perfilSenhas {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
}
.perfilSenhasAnt {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-top: 2%;
}
.editarSenhaEsquerda {
    margin-bottom: 4% !important;
}
.editarSenhaAtual {
    margin-top: 3% !important;
    align-self: center;
    width: 220px;
}
.loadingEditarPerfil {
    color: var(--escuro) !important;
    padding-bottom: -5px !important;
    width: 18px !important;
    height: 18px !important;
}
.perfilCRM input::-webkit-outer-spin-button,
.perfilCRM input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.perfilCRM input[type="number"] {
    -moz-appearance: textfield;
}
@media screen and (max-width: 890px) {
    .perfilInfo {
        flex-direction: column;
        width: 80vw;
    }
    .perfilInfo > div:first-child {
        width: 100%;
    }
    .perfilInfo > div:nth-child(2) {
        width: 100%;
    }
    .perfilDados,
    .perfilDadosD {
        padding-right: 15vw;
        padding-left: 15vw;
    }
    .perfilNiver {
        width: 170px;
    }
    .perfilUF {
        width: 80px;
    }
    .perfilSenhasAnt, .editarSenhaAtual {
        width: 60%;
    }
}
