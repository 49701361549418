.cardClinica {
    width: 100%;
    min-height: max-content;
    border-radius: 8px;
    background-color: var(--escuro);
    color: #fff;
}
.cardClinica > div:first-child {
    width: 100%;
    text-align: right;
}
.divIconsClinicaCard {
    display: flex;
    flex-direction: column;
}
.editarClinica {
    position: relative;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    z-index: 0;
    cursor: pointer;
}
.apagarClinica {
    position: relative;
    margin-bottom: -45px;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: auto;
    z-index: 0;
    cursor: pointer;
}
.cardClinicaInfo {
    display: flex;
    flex-direction: column;
    padding: 5px 8px 5px 15px;
}
.cardClinicaInfo > p:first-child {
    max-width: 96%;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 2%;
    margin-top: -18px;
}
.cardClinicaInfo div {
    display: flex;
}
.cardClinicaInfo div p {
    max-width: 100%;
    margin: 0;
    margin-bottom: 7px;
    margin-left: 4%;
}
@media screen and (max-width: 530px) {
    .cardClinicaInfo div {
        flex-direction: column;
    }
    .cardClinicaInfo div p:nth-child(2) {
        margin-left: 9%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
