:root {
  /* Na Id Visual (escrito) */
  --escuro: #170647;
  --claro: #00d4ce;
  --verde: #008059;
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-Regular.otf");
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-RegularItalic.otf"); 
  font-style: italic;
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-Bold.otf"); 
  font-weight: 700;
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-BoldItalic.otf"); 
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-Medium.otf"); 
  font-weight: 500;
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-MediumItalic.otf"); 
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-Light.otf"); 
  font-weight: 300;  
}
@font-face {
  font-family: "LemonMilk";
  src: url("./assets/fonts/LemonMilk/LEMONMILK-LightItalic.otf"); 
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-Regular.ttf");
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Catamaran";
  src: url("./assets/fonts/Catamaran/Catamaran-Black.ttf");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Alerta {
  z-index: 2;
  position: absolute;
  right: 5px;
  top: 5px;
}
