.loginContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	color: var(--escuro);
	background-image: url(./../../assets/backverde.png);
	background-size: cover;
}

.voltarIcone {
	position: absolute;
	top: 20px;
	left: 20px;
	font-size: 280% !important;
	color: black;
}

.loginTitulo {
	display: flex;
	justify-content: center;
	align-self: center;
	width: 43%;
	font-family: "LemonMilk";
	font-size: 270%;
	font-weight: bold;
}

.loginDivider {
	align-self: center;
	width: 5px;
	height: 80vh;
	background-color: var(--escuro);
	border-radius: 3px;
}

.loginForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	width: 53%;
}

.inputLogin {
	width: 440px;
	margin-bottom: 15px !important;
	border-radius: 5px;
	background-color: white;
}

.esqueciLogin {
	color: var(--escuro);
	font-weight: 600;
	font-size: 90%;
	margin-top: -8px;
	margin-bottom: 8px;
	text-decoration: none;
	cursor: pointer;
}

.divIconsLogin {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	max-width: 320px;
	width: 40%;
	margin: 1.3vh 0;
}

.gIconLogin {
	background-image: url(./../../assets/GoogleIcon.png);
	background-size: cover;
	margin-top: 3px;
	width: 45px;
	height: 45px;
	cursor: pointer;
}

.fIconLogin {
	color: #3b5998;
	font-size: 50px !important;
	cursor: pointer;
}
.AIconLogin {
	color: black;
	font-size: 50px !important;
	cursor: pointer;
}

.textoCadastro {
	width: 220px;
	font-weight: bold;
	text-align: center;
	padding: 25px 0px 35px;
	color: var(--escuro);
}
.textoCadastro p {
	margin: 0;
}

.textoCadastro a:-webkit-any-link,
.textoCadastro a:-webkit-any-link:active {
	color: var(--escuro) !important;
}

.btnLogin {
	width: 150px;
	outline: none;
	border: 0;
	border-radius: 5px;
	cursor: pointer;
	font-size: 105%;
	color: white;
	background-color: var(--escuro);
}
.loadingLogin {
	color: white !important;
	width: 25px !important;
	height: 25px !important;
}
.loginModalInput {
	width: 49%;
	margin-right: 10px !important;
	margin-bottom: 10px !important;
}
.loginModalInputAssis {
	width: 48%;
	margin-right: 10px !important;
	margin-bottom: 10px !important;
}
.loginModalInputEsq {
	width: 49%;
}
.loginCRM {
	width: 100%;
	margin-right: 10px !important;
	margin-bottom: 10px !important;
}
.loginUF {
	width: 75px;
	margin-bottom: 10px !important;
}
.birthday {
	width: 210px;
	margin-bottom: 10px !important;
}
.loginModalDivCRM {
	display: flex;
	flex-direction: row;
}
.loginModalDivCRM > div:last-child {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.loginModalDivAssisNiver {
	align-items: flex-end;
}
.loginModalDivAssis {
	flex-direction: row-reverse !important;
	width: 100%;
	justify-content: flex-end;
}
.loginModalDivAssis .birthday {
	width: 220px;
}
.loginModalDivCRM input::-webkit-outer-spin-button,
.loginModalDivCRM input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}
.loginModalDivCRM input[type="number"] {
	-moz-appearance: textfield;
}
.loadingLoginSocialdiv {
	position: absolute;
	width: 100vw;
	height: 100vh;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(219, 219, 219, 0.288);
}
.loadingLoginSocial {
	color: var(--claro) !important;
	width: 100px !important;
	height: 100px !important;
	/* background-color: rgba(88, 88, 88, 0.288); */
}
@media screen and (max-width: 860px) {
	.loginContainer {
		flex-direction: column;
		background-size: cover;
		padding-bottom: 5vh;
	}
	.voltarIcone {
		top: 30px;
	}
	.loginTitulo {
		margin-top: 5%;
		margin-bottom: 2%;
	}
	.loginDivider {
		width: 80%;
		height: 5px;
	}
	.loginForm {
		margin-top: 20px;
	}
	.inputLogin {
		width: 130%;
		margin-bottom: 30px !important;
	}
	.divIconsLogin {
		width: 100%;
	}
	.loginModalInput {
		width: 100%;
		margin-right: 0px !important;
		margin-bottom: 10px !important;
	}
	.loginModalInputEsq {
		width: 100%;
	}
	.loginCRM {
		width: 100%;
		margin-right: 0px !important;
		margin-bottom: 10px !important;
	}
	.loginUF {
		width: 49%;
		margin-right: 10px !important;
		margin-bottom: 10px !important;
	}
	.birthday {
		width: 49%;
		margin-bottom: 10px !important;
	}
	.loginModalDivCRM {
		flex-wrap: wrap;
		flex-direction: column;
	}
	.loginModalDivAssis .loginUF {
		margin-right: 0px !important;
	}
	.loginModalDivAssis .birthday {
		margin-right: 10px !important;
	}
}
