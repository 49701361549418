.cardProcedimentoContainer {
    width: 340px;
    min-height: max-content;
    border-radius: 8px;
}
.cardProcedTituloDiv {
    position: relative;
    background-color: white;
    box-shadow: -2px -2px 2px 2px rgba(77, 77, 77, 0.418);
    max-width: 80%;
    margin-bottom: -5%;
    color: grey;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 8px;
}
.cardProcedTitulo {
    border-radius: 8px;
    padding-left: 10px;
    margin-bottom: 5%;
    color: grey;
    font-weight: bold;
}
.cardProcedTituloDiv p {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin: 0;
}
.editarProced {
    position: relative;
    margin-bottom: -45px;
    margin-top: 20px;
    margin-left: auto;
    z-index: 0;
    cursor: pointer;
}
.apagarProced {
    position: relative;
    margin-bottom: -75px;
    margin-top: 50px;
    margin-left: auto;
    z-index: 0;
    cursor: pointer;
}
.verProced {
    position: relative;
    margin-bottom: -15px;
    margin-top: -10px;
    margin-left: auto;
    z-index: 0;
    cursor: pointer;
}
.editarProced:hover, .apagarProced:hover {
    color: var(--claro) !important;
}
.cardProcedInfoDiv {
    background-color: var(--escuro);
    margin-left: 3%;
    padding-top: 8%;
    border-radius: 7px;
    padding-right: 5%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
}
.cardProcedInfoDiv p {
    margin: 0;
    color: white;
    margin-bottom: 0.5%;
}
.cardProcedInfoDiv p:nth-child(2){
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.cardProcedCheck {
    margin-left: auto;
    height: 25px;
    width: 25px;
    border: 2px solid #f7f7f7;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
.cardProcedCheck:hover {
    border-color: var(--claro);
}
.cardProcedDivBtns {
    text-align: center;
    color: var(--escuro) !important;
}
