.perfilContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.perfilTitulo {
	width: 100%;
	margin: 3vh 0;
	text-align: center;
	font-family: "LemonMilk";
	font-weight: 600;
	font-size: 230%;
	color: var(--escuro);
}
.perfilDivider {
	width: 70vw;
	height: 5px;
	border-radius: 55px;
	margin: auto;
	background-color: var(--escuro);
}
.perfilInfo {
	display: flex;
	width: 70vw;
}
.perfilInfo > div:first-child {
	display: flex;
	flex-direction: column;
	width: 50%;
}
.perfilUserIcon {
	font-size: 1000% !important;
	margin-top: 8px;
	align-self: center;
	color: var(--escuro);
}
.perfilDados,
.perfilDadosD {
	display: flex;
	flex-direction: column;
	padding-right: 10%;
	padding-left: 10%;
	margin-top: 3vh;
}
.perfilDadosD {
	padding-right: 35%;
}
.perfilDados > div,
.perfilDadosD > div {
	margin-bottom: 13px;
}
.perfilInfo > div:nth-child(2) {
	width: 50%;
}
.perfilInfo > div:nth-child(2) > p:first-child {
	margin: 5vh 0 1vh 5%;
	font-family: "LemonMilk";
	font-weight: 600;
	font-size: 130%;
	color: var(--escuro);
}
.perfilInfoDivider {
	width: 50%;
	height: 4px;
	margin-left: 4%;
	border-radius: 15px;
	background-color: var(--escuro);
}
.perfilNiver {
	width: 170px;
}
.perfilUF {
	width: 80px;
}
.perfilBtns {
	display: flex;
	justify-content: center;
	margin-top: 20%;
}
.perfildivBtn {
	display: flex;
	margin-top: 8%;
	justify-content: space-evenly;
	flex-wrap: wrap;
	gap: 10px;
}
.perfilEditarLinkbtn {
	padding: 2px 15px;
	border-radius: 5px;
	border: 2px solid var(--escuro);
	color: var(--escuro);
	background-color: white;
	font-size: 1.1rem;
	font-family: "Catamaran";
	font-weight: 700;
	text-decoration: none;
}
.deleteAccountbtn {
	height: 37px;
	padding: 2px 15px;
	border-radius: 5px;
	border: 2px solid red;
	color: white;
	background-color: red;
	font-size: 1.1rem;
	font-family: "Catamaran";
	font-weight: 700;
	text-decoration: none;
}
.perfilEditarBtns {
	display: none;
}
.perfilUserInfo {
	margin: 2% 0;
	padding: 8px;
	padding-left: 20px;
	background-color: var(--escuro);
	color: white;
	border-radius: 5px;
}

@media screen and (max-width: 890px) {
	.perfilInfo {
		flex-direction: column;
		width: 80vw;
	}
	.perfilInfo > div:first-child {
		width: 100%;
	}
	.perfilInfo > div:nth-child(2) {
		width: 100%;
	}
	.perfilDados,
	.perfilDadosD {
		padding-right: 15vw;
		padding-left: 15vw;
	}
	.perfilNiver {
		width: 170px;
	}
	.perfilUF {
		width: 80px;
	}
	.perfildivBtn {
		margin-bottom: 50px;
	}
}
