.planosContainer {
  min-height: max-content;
}

.planostitulo {
  color: var(--escuro);;
  font-size: 6vh;
  margin-top: 6%;
  text-align: center;
}

.divider {
  width: 80%;
  border-bottom: 5px solid var(--escuro);;
  border-radius: 4px;
  margin-left: 10%;
  margin-right: 10%;
}

.planoCard {
  margin-top: 4%;
  margin-bottom: 4%;
  margin-right: 10%;
  margin-left: 5%;
}

.planoTitulo {
  color: #ffffff;
  background-color: var(--escuro);;
  margin-left: 5%;
  border-radius: 8px;
  font-size: 3vh;
  padding-bottom: 1vh;
  margin-top: -0.2%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  display: block;
  position: absolute;
}

.planoInfo {
  flex-direction: row;
  display: flex;
  background-color: var(--claro);;
  margin-right: 3%;
  margin-left: 8%;
  min-height: 300px;
  border-radius: 10px;
}

.textoPlanosDiv {
  margin-left: 2%;
  padding-right: 5%;
  font-size: 15pt;
  margin-top: 10%;
  margin-bottom: 3%;
  margin-left: auto;
  margin-right: auto;
}

.textoPlanos {
  color: var(--escuro);;
}

.valorPlanosDiv {
  flex-direction: row;
  display: flex;
  margin-top: 3%;
  background-color: #ffffff;
  margin-right: -4%;
  margin-left: 8%;
  max-height: 200px;
  border-radius: 6px;
  padding-left: 2%;
  padding-right: 2%;
  box-shadow: 5px 6px 7px 5px rgba(50, 50, 50, 0.493);
}

.valorPlanos {
  color: var(--escuro);;
  margin-top: 40%;
  font-weight: bolder;
  font-size: 3vh;
}

@media screen and (max-width: 700px) {

  .planostitulo {
    color: var(--escuro);;
    font-size: 4vh;
    margin-top: 8%;
  }

  .textoPlanosDiv {
    margin-top: 20%;
    margin-left: 10%;
  }

  .planoInfo {
    flex-direction: column;
    margin-bottom: 10%;
    min-height: 200px;
  }

  .valorPlanosDiv {
    flex-direction: row;
    margin-top: 10%;
    margin-right: 2%;
    margin-left: 2%;
  }
  
  .valorPlanos {
    margin-top: 0%;
    margin: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 3vh;
    justify-content: center;
    align-content: center;
  }
}
