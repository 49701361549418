.divTipoUsuario {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}
.logo {
    background-color: var(--escuro);
    width: 50%;
    display: flex;
    justify-content: center;
}
.voltarIcone {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 280% !important;
}
.imglogo {
    width: 50%;
    object-fit: contain;
    vertical-align: middle;
}
.selecaoUser {
    width: 55%;
}
.tituloSelecao {
    position: absolute;
    padding-top: 8vh;
    padding-left: 6vw;
    font-size: 250%;
    font-weight: bolder;
    color: var(--escuro);
}
.selecao {
    display: flex;
    height: 100vh;
    flex-direction: row;
    justify-content: space-evenly;
}
.cardSelecao {
    align-self: center;
    text-decoration: none;
}
.cardSelecao div {
    color: var(--escuro);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.btnSelecao {
    border: 0;
    font-size: 105%;
    font-weight: bolder;
    outline: none;
    border-radius: 5px;
    color: white;
    padding: 10px;
    background-color: var(--escuro);
}
.cardSelecao:hover div {
    color: var(--escuro);
}
.cardSelecao:hover .btnSelecao {
    background-color: var(--escuro);
    cursor: pointer;
}
.usericonselecao {
    font-size: 180px !important;
}

@media screen and (max-width: 700px) {
    .divTipoUsuario {
        display: block;
        width: 100vw;
        height: 100vh;
    }
    .logo {
        width: 100%;
        padding: 30px 0;
        background-color: var(--escuro);
    }
    .selecaoUser {
        width: 100%;
    }
    .imglogo {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .selecao {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: max-content;
        padding-top: 40%;
        padding-bottom: 10%;
    }
    .tituloSelecao {
        font-size: 8vw !important;
        padding-top: 4vh;
    }
    .cardSelecao {
        width: 35%;
    }
    .btnSelecao {
        font-size: 5vw !important;
    }
    .cardSelecao div {
        flex-direction: column;
        justify-content: flex-start;
    }
    .usericonselecao {
        font-size: 32vw !important;
    }
}
