.cardEquipe {
    position: relative !important;
    width: 300px;
    min-height: max-content;
    border-radius: 8px;
    background-color: var(--escuro);
    color: #fff;
}

.cardEquipe>div:first-child {
    width: 100%;
    text-align: right;
}

.divIconEquipeCardf {
    display: flex;
    flex-direction: column;
}

.apagarEquipe {
    position: relative;
    margin-bottom: -45px;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: auto;
    z-index: 0;
    cursor: pointer;
}

.cardEquipeInfo {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}

.cardEquipeInfo>p:first-child {
    max-width: 100%;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 1.3rem;
    padding-right: 5%;
    margin-bottom: 2%;
}

.cardEquipeInfo>p {
    max-width: 100%;
    margin: 0;
    margin-bottom: 7px;
    margin-left: 4%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardEquipeAprovarDiv {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: center;
    align-self: center;
}

.cardEquipeAprovarButton,
.cardEquipeRemoverButton {
    border-radius: 5px;
    width: 40%;
    height: 25px;
    background-color: #00AFAF;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.cardEquipeRemoverButton {
    background-color: #e03434;
}

.loadingCardEquipe {
    color: var(--escuro) !important;
    height: 25px !important;
    width: 25px !important;
}