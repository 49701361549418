.procedimentosContainer {
    width: 100%;
}
.filtroProcedimentos {
    margin: auto;
    height: 70px;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.filtroProcedimentos > .MuiFormControl-marginDense, .divFiltroProcedimentos > .MuiFormControl-marginDense  {
    margin: 0px;
}
.procedimentosSelectInput {
    min-width: 125px;
}
.procedimentosInput {
    width: 20vw;
    max-width: 500px;
    margin: 0px 15px !important;
}
.procedimentosSelectResponsivo {
    display: none !important;
}
.procedimentosBtnFiltro {
    width: 130px;
    height: 33px;
    outline: none;
    border: 1px solid var(--escuro);
    border-radius: 5px;
    background-color: white;
    color: var(--escuro);
    font-family: "Catamaran";
    font-weight: 700;
    font-size: 100%;
}
.procedimentosBtnFiltro:hover {
    cursor: pointer;
}
.cardsProcedSection {
    justify-content: center;
    justify-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 390px));
    column-gap: 9px;
    row-gap: 14px;
    margin-bottom: 2%;
    margin-left: 3px;
    margin-right: 3px;
}
.procedLoader {
    height: 30px;
    width: 30px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    left: 0;
    right: 0;
    color: var(--claro) !important;
}
.procedimentosArrayVazio {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "LemonMilk";
    font-size: 100%;
    font-weight: 700;
}
@media screen and (max-width: 500px) {
    .filtroProcedimentos {
        flex-direction: column;
        height: max-content;
        width: 100%;
        margin-top: 4%;
        margin-bottom: 4%;
    }
    .divFiltroProcedimentos {
        display: flex;
        flex-direction: row;
        width: 80vw;
        justify-content: space-evenly;
    }
    .procedimentosSelect {
        display: none !important;
    }
    .procedimentosInput {
        width: 80vw;
        margin-bottom: 5% !important;
    }
    .procedimentosSelectResponsivo {
        display: block !important;
        min-width: 140px;
        margin-right: 5% !important;
    }
}
