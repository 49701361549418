.CadastroProcedimento {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-evenly;
    width: 100%;
    align-content: center;
}

.cadProcedLeft {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 35%;
    left: 8vw;
}

.cadProcedTitulo {
    text-align: center;
    font-size: 230%;
    font-weight: 700;
    font-family: "LemonMilk";
    color: var(--escuro);
    margin-top: -5%;
    align-self: center;
}

.cadProcedTitulo p {
    text-align: center;
    margin: 0;
}

.CadastroProcedimentoDivider {
    min-height: 75vh;
    width: 5px;
    background-color: var(--escuro);
    border-radius: 5px;
}

.cadProcedForm {
    position: absolute;
    right: 8vw;
    top: 27%;
    display: flex;
    flex-direction: column;
    margin: 0px 15px;
    justify-content: center;
}

.cadProcedForm>div {
    margin-bottom: 10px;
}

.cadProcedFormDiv {
    display: flex;
    flex-direction: row;
}

.cadProcedInput {
    margin-right: 10px !important;
}

.cadProcedTuss {
    width: 70% !important;
}

.divValorTuss {
    display: flex;
}

.cadProcedValor {
    flex: 8;
}

.cadProcedRmvIcon {
    align-self: center;
    margin-left: 5px;
    color: var(--escuro);
    cursor: pointer;
    flex: 2;
}

.cadProcedAddIcon {
    align-self: center;
    margin-right: 7px;
    font-size: 27px !important;
    color: var(--escuro);
    cursor: pointer;
    margin-bottom: 10px;
}

.cadProcedBtn {
    margin-top: 2%;
    width: 60%;
    align-self: center;
    border: 0;
    font-size: 1rem;
    font-family: "LemonMilk";
    font-weight: 700;
    outline: none;
    border-radius: 5px;
    color: white;
    padding: 10px;
    background-color: var(--escuro);
}

.cadProceddivArquivos {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.cadProcedFile[type="file"] {
    display: none;
}

.cadProcedFileLabel {
    align-self: center;
    width: 110px;
    border: 2px solid var(--escuro);
    border-radius: 5px;
    color: var(--escuro);
    cursor: pointer;
    margin: auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 800;
    padding: 6px 20px;
}

.cadProcedBaixarBtn {
    align-self: center;
    width: 151px;
    border: 2px solid var(--escuro);
    border-radius: 5px;
    color: var(--escuro);
    cursor: pointer;
    margin: auto;
    background-color: white;
    font-size: 102%;
    margin-top: 5%;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 800;
    padding: 6px 10px;
}

.cadProcedData {
    width: 49% !important;
}

.loadingCadProced {
    color: white !important;
    width: 25px !important;
    height: 25px !important;
}

@media screen and (max-width: 1050px) {
    .cadProcedForm {
        right: 5vw;
        top: 18%;
    }
    .cadProcedTitulo {
        font-size: 3.3vw;
    }
    .cadProcedForm {
        margin: 0px 2%;
    }
    .cadProcedForm>div {
        width: 45vw;
    }
    .cadProcedData {
        width: 100% !important;
    }
    .cadProcedTuss {
        width: 100% !important;
    }
    .cadProcedTuss {
        width: 100% !important;
    }
    .cadProcedValor {
        width: 90% !important;
    }
    .cadProcedRmvIcon {
        margin-left: auto;
        margin-right: 7px;
    }
    .cadProcedAddIcon {
        margin-bottom: 15px;
    }
    .cadProcedFormDiv {
        flex-direction: column;
    }
    .cadProcedFormDiv>div:nth-child(1) {
        margin-bottom: 10px;
    }
    .cadProcedInput {
        margin-right: 0px !important;
    }
    .cadProceddivArquivos {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .CadastroProcedimento {
        flex-direction: column;
        align-items: center;
    }
    .cadProcedLeft {
        position: absolute;
        width: 100%;
        left: auto;
        top: 13%;
        right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cadProcedTitulo {
        font-size: 5.2vw;
    }
    .CadastroProcedimentoDivider {
        min-height: 5px;
        width: 80vw;
        margin-top: 5%;
        margin-bottom: 10%;
        border-radius: 5px;
        background-color: var(--escuro);
    }
    .cadProcedForm {
        top: 27%;
        left: auto;
        right: auto;
    }
    .cadProcedForm>div {
        width: 80vw;
    }
}