.divCadastro {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: row;
}
.voltarIcone {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 280% !important;
    color: black;
}
.divCadastroTitulo {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 43%;
    font-size: 250%;
    font-weight: bolder;
}
.CadastroDivider {
    align-self: center;
    max-width: 5px;
    width: 5px;
    height: 88vh;
    background-color: var(--escuro);
    border-radius: 3px;
}
.divCadastroForm {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}
.inputCadastro {
    width: 450px;
    margin-bottom: 10px !important;
}
.DDD {
    width: 100px;
    margin-right: 10px !important;
}
.divCadastroForm > div:nth-child(3) {
    display: flex;
    flex-direction: row;
}
.email {
    width: 270px;
    margin-right: 10px !important;
}
.Tel {
    width: 170px;
    margin-right: 10px !important;
}
.Tel2 {
    width: 170px;
}
.esp {
    width: 270px;
}
.CRM {
    width: 310px;
    margin-right: 10px !important;
}
.CRM input::-webkit-outer-spin-button,
.CRM input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.CRM input[type="number"] {
    -moz-appearance: textfield;
}
.UF {
    margin-top: 0 !important;
    width: 130px;
}
.CPF {
    width: 200px;
    margin-right: 10px !important;
}
.Niver {
    margin-top: 0 !important;
    width: 240px;
}
.senha-e {
    margin-top: 0 !important;
    width: 220px;
    margin-right: 10px !important;
}
.senha {
    margin-top: 0 !important;
    width: 220px;
}
.Cadastrobtn {
    margin-top: 2%;
    width: 45%;
    align-self: center;
    border: 0;
    font-size: 1rem;
    font-family: "LemonMilk";
    font-weight: 700;
    outline: none;
    border-radius: 5px;
    color: white;
    background-color: var(--escuro);
}

@media screen and (max-width: 730px) {
    .divCadastro {
        flex-direction: column;
        width: 90%;
        margin: auto;
        margin-top: 55px;
    }
    .divCadastroTitulo {
        width: 100%;
    }
    .CadastroDivider {
        min-height: 5px;
        height: 5px;
        min-width: 82vw;
        margin-bottom: 55px;
        margin-top: 25px;
    }
    .divCadastroForm {
        width: 100%;
    }
    .inputCadastro {
        width: 90%;
    }
    .DDD {
        width: 10%;
    }
    .divCadastroForm > div:nth-child(n + 3) {
        width: 90%;
        display: inline-flex;
    }
    .divCadastroEmail {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .email {
        width: 100%;
    }
    .Tel {
        width: 40%;
    }
    .Tel2 {
        width: 100%;
    }
    .esp {
        width: 60%;
    }
    .CPF {
        width: 45%;
    }
    .Niver {
        width: 54%;
    }
    .CRM {
        width: 72%;
    }
    .UF {
        width: 27%;
    }
    .senha-e,
    .senha {
        width: 50%;
    }
    .Cadastrobtn {
        width: 50%;
    }
}
