.atendimentoWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 10px;
}
.atendimentoWrapper p {
    font-family: 'Catamaran';
}
.atendimentoEsquerda {
    width: 50%;
    padding-left: 6%;
}
.atendimentoDireita {
    width: 50%;
    padding-left: -6%;
}
.atendimentoHeader {
    font-size: 120%;
    font-family: 'LemonMilk' !important;
    margin-left: -3%;
}
.atendimentoDivider {
    background-color: var(--escuro);
    height: 2px;
    margin-left: -2%;
    border-radius: 5px;
    width: 40%;
}
@media screen and (max-width: 500px) {
    .atendimentoWrapper {
        flex-direction: column;
    }
    .atendimentoDireita {
        width: auto;
        padding-left: 6%;
    }
    .atendimentoEsquerda {
        width: auto;
    }
    .atendimentoDivider {
        background-color: var(--escuro);
        width: 50%;
    }
}