.divFaq {
    margin-top: 5vh;
    display: flex;
    color: var(--escuro);
    flex-direction: row;
    align-items: center;
    margin-bottom: 5%;
}
.tituloFaq {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 220%;
    flex: 2;
    font-family: "LemonMilk";
    font-weight: 600;
}
.tituloFaq p:first-child{
    color: var(--claro);
}
.tituloFaq p{
    padding-left: 8vw;
    margin: 0;
}
.dividerFaq {
    width: 5px;
    height: 75vh;
    background-color: var(--escuro);
    border-radius: 3px;
}
.perguntasFaq {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 170%;
}
.perguntaFaq { 
    width: 80%;
    margin: 0;
    margin-bottom: 15px;
    cursor: pointer;
}
.perguntaFaq div:first-child {
    display: flex;
    justify-content: space-between;
}
.perguntaFaq p:first-child {
    margin: 0;
    padding-right: 2%;
    padding-left: 2%;
    font-weight: bold;
}
.abrirIcon {
    align-self: center;
    font-size: 45px !important;
}
.bottomLineFaq {
    align-self: center;
    width: 100%;
    height: 3px;
    background-color: var(--escuro);
}
.respostaPergunta {
    font-size: 80%;
    text-align: justify;
    padding-left: 5%;
    padding-top: 2vh;
    display: none;
    transition: 200ms ease;
}
.openRes {
    display: flex;
}
.openIcon {
    transition: transform 400ms;
    transform: rotate(-180deg);
}
@media screen and (max-width: 800px) {

    .tituloFaq { 
        font-size: 5vw;
    }
    .tituloFaq p{
        padding-left: 2vw;
    }
}
@media screen and (max-width: 660px) {

    .divFaq {
        flex-direction: column;
        margin-top: 2vh;
    }
    .tituloFaq { 
        width: 100%;
        font-size: 5vw;
        margin-bottom: 3vh;
    }
    .tituloFaq p{
        padding-left: 35vw;
    }
    .dividerFaq {
        display: none;
    }
    .perguntasFaq {
        font-size: 4vw;
    }
    .abrirIcon {
        font-size: 10vw !important;
    }
}