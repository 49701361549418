.NavbarItens{
    background: linear-gradient(90deg, var(--escuro) 0%, var(--escuro) 100%);
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo2{
    color: #fff;
    justify-self: start;
    margin-left: 0px;
    cursor: pointer;
    width: 100px;
    padding-right: 10px;
    align-items: flex-start;
    text-decoration: none;
}

.fa-react{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(4, auto);
    /* grid-gap: 10px; */
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links{
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: solid 1px;
    border-radius: 6px;
    margin-right: 30px;
}

.nav-links2{
    color: var(--escuro);
    text-decoration: none;
    background-color: #ffffff;
    padding: 0.5rem 1rem;
    border: solid 1px;
    border-radius: 6px;
    margin-right: 30px;
}

.nav-links:hover {
    background-color: var(--claro);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars{
    color: #fff;
    min-height: 50px;
}

.nav-links-mobile{
    display: none;
}

.menu-icon{
    display: none;
}

@media screen and (max-width: 960px){
    .NavbarItems{
        position: relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 61px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: var(--escuro);
        left: 0;
        text-align: center;
        justify-content: center;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 2;
    }
    
    a.nav-links{
        position: absolute;
        padding: 2em 0;
        width: 100%;
        left: -100vw;
        display: table;
        border: none;
    }

    a.nav-links2{
        position: absolute;
        padding: 2em 0;
        width: 100%;
        left: -100vw;
        display: table;
        border: none;
        background-color: var(--escuro);
        color: #fff;
    }

    .active .nav-links, .active .nav-links2{
        left: 0;
        position: relative;
    }

    .nav-links:hover, .nav-links2:hover {
        background-color: var(--claro);
        border-radius: 0;
    }

    .navbar-logo2{
        position: absolute !important;
        top: 5px;
        left: 0;
        width: 100px;
        margin-left: 5%;
        cursor: pointer;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: -8px;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.5rem;
        cursor: pointer;
    }

    .fa-times{
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: var(--claro);
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: var(--claro);
        transition: 250ms;
    }

    ul{
        padding-inline-start: 0;
    }

    .buttonnav2{
        display: none;
    }
}
