.homeSobre {
    color: var(--escuro);
}
.homeSobreTitulo {
    margin-top: 4%;
    margin-left: 5%;
    margin-bottom: 1.8%;
    color: var(--escuro);
    font-family: "LemonMilk";
    font-size: 170%;
    font-weight: 700;
}
.homeSobreParte1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 3%;
}
.textoquemsomos {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1.2rem;
}
.quadrado {
    border-radius: 5px;
    background-color: var(--claro);
    min-width: 250px;
    min-height: 200px;
}
.homeSobreParte2 {
    display: flex;
    flex-direction: row;
}
.homeSobreParte2 > div:nth-child(1) {
    width: 300px;
    margin-right: 65px;
}
.quadrado1 {
    min-width: 200px;
    height: 200px;
    position: absolute;
    margin-top: 30px;
    margin-left: 80px;
    z-index: -1;
    border-radius: 5px;
    background-color: var(--claro);
}
.quadrado2 {
    min-width: 200px;
    height: 200px;
    margin-left: 115px;
    border-radius: 5px;
    background-color: var(--escuro);
}
.textoquemsomos2 {
    margin-right: 5%;
    font-size: 1.2rem;
    margin-top: 1%;
}
.homeAppTitulo {
    margin-top: 6%;
    margin-left: 5%;
    margin-bottom: 2%;
    color: var(--escuro);
    font-family: "LemonMilk";
    font-weight: 700;
    font-size: 2.5rem;
}
.homeAppContent {
    flex-direction: row;
    display: flex;
    background-color: var(--claro);
    margin-right: 3%;
    margin-left: 3%;
    border-radius: 10px;
    margin-bottom: 5%;
}
.homeAppTexto {
    width: 200%;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 15pt;
    margin-top: 3%;
    margin-bottom: 3%;
    color: var(--escuro);
}
.homeAppImagem {
    width: 90%;
    border-radius: 6px;
    margin-top: -60px;
    margin-bottom: 50px;
    margin-right: 50px;
}

@media screen and (max-width: 1170px) {
    .homeAppImagem {
        margin-top: -20px;
    }
}
@media screen and (max-width: 930px) {
    .homeAppImagem {
        margin-top: -10px;
    }
    .quadrado {
        display: none;
    }
    .homeSobreTitulo {
        margin: 5%;
        text-align: center;
    }
    .textoquemsomos {
        margin-left: 5%;
        margin-right: 5%;
        padding: 20px 40px;
        border-radius: 5px;
        z-index: 0;
        background-color: var(--claro);
    }
    .homeSobreParte2 > div:nth-child(1) {
        display: none;
    }
    .textoquemsomos2 {
        margin-right: 5%;
        margin-left: 5%;
        padding: 20px 40px;
        border-radius: 5px;
        z-index: 0;
        background-color: var(--escuro);
        color: white;
    }
    .homeAppTitulo {
        text-align: center;
        margin-left: 0;
    }
    .homeAppContent {
        flex-direction: column;
    }
    .homeAppTexto {
        width: 90%;
        margin-left: 5%;
    }
    .homeAppImagem {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 700px) {
    .quadrado {
        display: none;
    }
    .titulo {
        width: 100%;
    }
    .quemsomostitulo {
        color: var(--escuro);
        font-size: 5vh;
        margin-top: 6%;
        text-align: center;
        padding-left: 0%;
    }
    .textoquemsomos {
        margin-left: 5%;
        padding: 20px 40px;
        font-size: 15pt;
        background-color: var(--claro);
        margin-right: 5%;
        border-radius: 5px;
    }
    .textoquemsomos2 {
        margin-left: 5%;
        padding: 20px 40px;
        font-size: 15pt;
        background-color: var(--escuro);
        margin-right: 5%;
        border-radius: 5px;
    }
    .quemsomostexto2 {
        color: white;
    }
    .quadrado1,
    .quadrado2 {
        display: none;
    }
    .homeAppTitulo {
        color: var(--escuro);
        font-size: 10vw;
        margin-top: 6%;
        text-align: center;
        padding-left: 0%;
    }
    .homeAppImagem {
        margin-bottom: -2%;
    }
}
