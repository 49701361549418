.EquipeContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.EquipeCards {
    justify-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
    column-gap: 12px;
    row-gap: 30px;
    margin: 0 10px 10% 10px;
}
.add-equipe {
    text-align: center !important;
    margin-top: 2%;
    margin-bottom: 2%;
}
.botao-adicionar-assistente {
    background-color: var(--escuro) !important;
    margin-top: 30px !important;
    color: #fff !important;
    font-weight: bold !important;
    align-self: center;
    text-align: center !important;
}
.equipeLoader {
    height: 30px;
    width: 30px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    left: 0;
    right: 0;
    color: var(--claro) !important;
}
.equipeArrayVazio {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "LemonMilk";
    font-size: 100%;
    font-weight: 700;
}
.loadingEquipe {
    color: var(--escuro) !important;
    width: 25px !important;
    height: 25px !important;
}
