.graficoFiltro {
    display: flex;
    justify-content: center;
    margin-top: 2.5vh;
}
.graficoFiltro > div {
    min-height: 78px;
    display: flex;
    justify-content: center;
    align-content: center;
}
.graficoDate {
    width: 30%;
    margin-right: 10px !important;
}
.graficoInput {
    width: 40%;
    margin-right: 10px !important;
}
.graficoFiltro button {
    cursor: pointer;
}
.graficoBtn {
    width: 100px;
    height: 35px;
    align-self: center;
    margin-top: -18px;
    border-radius: 7px;
    margin-right: 10px;
    border: 2px solid var(--escuro);
    background-color: white;
    color: var(--escuro);
    font-family: "LemonMilk";
    font-weight: 700;
    font-size: 0.7rem;
}
.loadingFiltroGrafico {
    color: var(--escuro) !important;
    width: 20px !important;
    height: 20px !important;
}
.graficoBtnLimpar {
    width: 110px;
    height: 35px;
    align-self: center;
    margin-top: -18px;
    border-radius: 7px;
    border: 2px solid var(--escuro);
    background-color: white;
    color: var(--escuro);
    font-family: "LemonMilk";
    font-weight: 700;
    font-size: 0.7rem;
}
.graficoContainer > div {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}
.graficoDivGrafico {
    width: 60%;
    margin-left: 3%;
}
.graficoValores {
    min-height: 420px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: var(--escuro);
    font-family: "LemonMilk";
    font-size: 0.9rem;
    font-weight: 600;
}
.graficoRecebido,
.graficoReceber {
    width: 65%;
    display: flex;
    justify-content: space-between;
}
.graficoRecebido p,
.graficoReceber p {
    margin: 0;
}
.graficoDivider {
    width: 70%;
    height: 4px;
    margin: 10px 0;
    border-radius: 25px;
    background-color: var(--escuro);
}
.graficoBtnTipo {
    width: 170px;
    height: 35px;
    margin-top: 1.7vh;
    margin-left: 10px;
    border-radius: 7px;
    border: 2px solid var(--escuro);
    background-color: white;
    color: var(--escuro);
    font-family: "LemonMilk";
    font-weight: 700;
    font-size: 0.7rem;
}

@media screen and (max-width: 1300px) {
    .graficoValores {
        min-height: 0;
    }
    .graficoRecebido,
    .graficoReceber {
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .graficoRecebido p:nth-child(2),
    .graficoReceber p:nth-child(2) {
        margin-left: 20%;
    }
}
@media screen and (max-width: 940px) {
    .graficoFiltro > div:first-child {
        width: 60%;
    }
    .graficoContainer > div {
        flex-direction: column-reverse;
        align-items: center;
    }
    .graficoValores {
        width: 70vw;
        justify-content: center;
    }
    .graficoRecebido,
    .graficoReceber {
        width: 95%;
        flex-direction: row;
    }
    .graficoRecebido p,
    .graficoReceber p {
        margin: 0;
        font-size: 2vw;
        margin-left: 0 !important;
    }
    .graficoDivider {
        width: 100%;
    }
    .graficoDivGrafico {
        margin-top: 30px;
        width: 90vw;
        margin-left: -25px;
    }
}
@media screen and (max-width: 800px) {
    .graficoFiltro {
        flex-direction: column;
        align-items: center;
    }
    .graficoFiltro > div:first-child {
        width: 95%;
    }
    .graficoFiltro > div:nth-child(2) {
        min-height: 35px;
    }
    .graficoBtn {
        width: 120px;
        margin-right: 30px;
    }
    .graficoBtnLimpar {
        width: 130px;
    }
}
@media screen and (max-width: 420px) {
    .graficoFiltro > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        margin-bottom: 5vw;
    }
    .graficoDate,
    .graficoInput {
        width: 80%;
    }
    .graficoRecebido p,
    .graficoReceber p {
        font-size: 2.9vw;
    }
}
