.handlerLogo {
    width: 15%;
    position: absolute;
    bottom: 5%;
}
.handlerCard {
    max-width: 430px;
    max-height: 400px;
    border-radius: 5px;
    box-shadow: 7px 8px 7px 4px rgba(50, 50, 50, 0.1);
    width: 70vw;
    height: 70vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--escuro);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.handlerExpired {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    margin-right: 5px;
    margin-left: 5px;
    font-family: "Catamaran";
    font-size: 170%;
    font-weight: 800;
    color: white;
}
.handlerTitle {
    font-family: "Catamaran";
    font-weight: 800;
    font-size: 200%;
    color: white;
    margin: 0;
    margin-top: 5%;
}
.handlerText {
    color: white;
    margin: 0 5% 8%;
}
.handlerInput {
    color: white;
    width: 75%;
    margin-bottom: 3% !important;
}
.handlerInput .Mui-focused {
    color: white !important;
}
.handlerAcessar{
    color: white;
    margin: 0 5% 8%; 
    display: flex;
    justify-content: space-between;
    width: fit-content;
    cursor: pointer;
}
.handlerAcessarIcon {
    font-size: 120% !important;
    vertical-align: middle;
    margin-top: 3px;
}
.handlerAcessar:hover {
    transition: width 1s ease;
    width: 40%;
    color: var(--claro) !important;
}
.handlerBtnEmail {
    width: 60%;
    height: 45px;
    outline: none;
    border: 1px solid var(--escuro);
    border-radius: 5px;
    background-color: white;
    color: var(--escuro);
    font-family: "Catamaran";
    font-weight: 800;
    font-size: 130%;
    cursor: pointer;
}
.handlerBtnEmail:hover, .handlerBtn:hover {
    background-color: lightgray;
}
.handlerBtn {
    width: 130px;
    height: 33px;
    outline: none;
    border: 1px solid var(--escuro);
    border-radius: 5px;
    background-color: white;
    color: var(--escuro);
    font-family: "Catamaran";
    font-weight: 700;
    margin-top: 2%;
    font-size: 110%;
    cursor: pointer;
}
@media screen and (max-width: 535px) {
    .handlerLogo {
        width: 20%;
    }
    .handlerCard {
        width: 90vw;
        height: 70vh;
    }
}