.footerContainer {
  display: flex;
  justify-content: space-evenly;
  height: max-content;
  margin-top:auto; 
  z-index: 2;
  background-color: var(--escuro);
  color: white;
  font-size: 1rem;
  padding-bottom: 10px;
  text-decoration: none !important;
}
.footerLogo{
  display: flex;
  justify-content: center;
}
.footerLogo img {
  margin: auto;
  width: 100px;
  height: 60px;
}
.footerEndereco,
.footerLinks,
.footerApps {
  padding-top: 17px;
  display: flex;
  flex-direction: column;
}
.footerApps {
  min-width: 170px;
  min-height: 140px;
  text-align: left;
}
.footerContainer p:first-child {
  font-weight: bold;
  margin-bottom: 13px;
}
.footerLinks p:not(:first-child), .footerLinks a:not(:first-child) {
  margin-top: 0;
  margin-bottom: 10px;
  text-decoration: none;
  color: white;
}
.footerEndereco p:not(:first-child) {
  margin: 0;
}

@media screen and (max-width: 700px) {
  .footerLogo {
    display: flex;
    justify-content: center;
  }
  .footerLogo img {
    margin-top: 5%;
    width: 25vw;
    height: 15vw;
  }
  .footerContainer {
    flex-direction: column;
  }
  .footerEndereco,
  .footerLinks,
  .footerApps {
    margin-bottom: 2%;
    font-size: 13px;
  }
  .footerEndereco,
  .footerLinks,
  .footerApps {
    padding-left: 10vw;
  }
}
