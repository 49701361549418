.menuContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.menuContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 8%;
}
.menuTitulo {
  font-family: "Catamaran";
  text-align: center;
  color: var(--escuro);
  font-weight: 900;
  font-size: 180%;
  margin-top: 2%;
}
.menuDividerMaior {
  height: 5px;
  width: 70%;
  background-color: var(--escuro);
  border-radius: 3px;
  margin: auto;
  margin-bottom: 1.2%;
}
.menuUser {
  display: flex;
  flex-direction: row;
  color: var(--escuro);
  font-family: "Catamaran";
  font-size: 150%;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  margin-bottom: 5%;
}
.menuUsername {
  max-width: 40%;
  align-self: center;
  color: #ffffff;
  background-color: var(--escuro);
  border-radius: 5px;
  padding: 3px 10px;
}
.menuUsername p {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.menuUsername p {
  margin: 0;
}
.menuContainerLinks {
  margin: auto;
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: min-content;
  width: 45vw;
}
.menuLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2vh;
}
.menuSquare {
  width: 40px;
  height: 38px;
  background-color: #ffffff;
  box-shadow: -3px -3px 7px 3px rgba(50, 50, 50, 0.349);
}
.menuLinkTexto {
  align-self: center;
  vertical-align: middle;
  background-color: var(--escuro);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  width: 90%;
  white-space: nowrap;
  overflow-x: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  color: #ffffff;
  font-size: 130%;
}
.menuDividerMenor {
  height: 5px;
  width: 50vw;
  background-color: var(--escuro);
  border-radius: 3px;
  align-self: center;
  margin-top: 2%;
  margin-bottom: 3%;
}

@media screen and (max-width: 700px) {
  .menuContainerLinks {
    width: 75vw;
  }
  .menuDividerMenor {
    width: 75vw;
  }
  .menuUsername {
    font-size: 16px;
  }
  .menuLinkTexto {
    font-size: 16px;
  }
}
